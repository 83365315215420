@import url('https://fonts.googleapis.com/css2?family=Public+Sans&display=swap');

.userlist tr th, .userlist tr td {
    color: white;
}

.ribbon-top-right {
    top: -10px;
    right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
}

.ribbon-top-right::before {
    top: 0;
    left: 0;
}

.ribbon-top-right::after {
    bottom: 0;
    right: 0;
}

.ribbon-top-right span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
}

.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
}

.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 16px 0;
    background-color: #3498db;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: uppercase;
    text-align: center;
    font-size: 9px;
    padding-left: 18px;
}

body {
    font-family: 'Public Sans', sans-serif;
}

.main-table-box input:focus {
    background-color: black;
    color: white;
}

p.card-category {
    font-size: 20px;
}

.logo {
    margin-left: 0;
    padding: 0px 0px;
    width: 100%;
}

.logo img {
    max-width: 95px;
    padding: 5px;
    margin-top: 0 !important;
}

.table-main .pagination li a, .userlist .pagination li a {
    color: #000;
}


.container-fluid.dashb {
    padding-top: 9px;
}

.table-main .pagination, .userlist .pagination {
    justify-content: center;
    margin-top: 40px;
}

.card {
    background: transparent;
    border: 2px solid #525252;
    color: white;
    text-align: left;
}

.user-table i.fa {
    /* font-size: 60px; */
    margin: 3px 5px 0 0;
}

.user-table {
    min-height: calc(81vh - 0px);
}

.card-stats.card.subscriber-card {
    background: #646464;
    border: none;
    font-size: 35px;
}

.card-stats.card {
    padding: 25px;
}

.card-stats.card.user-card {
    background: #42a4db;
    border: none;
    font-size: 35px;
}

.numbers h4.card-title {
    font-size: 32px;
    font-weight: bold;
    white-space: nowrap;
}





.dropdown-menu-end i.fa.fa-lock {
    padding-right: 10px;
    font-size: 25px;
}

.full-width-sidebar .bg-login {
    height: 73vh;
}

.sidenav .col-lg-12.full-width-sidebar .bg-login {
    height: 100vh;
}



.top-bar {
    background: #171717;
}

.header-right ul {
    margin-right: 30px;
    margin-bottom: 0;
    margin-top: 9px;
}

.active-new .header-right ul {
    margin-top: 0;
}



.header-right li {
    list-style-type: none;
}

li.nav-item.dropdown button:after {
    border-top-color: white !important;
}

li.nav-item.dropdown button {
    top: -45px;
    right: -21px;
}

.dropdown-menu img {
    width: 30px;
    margin-right: 10px;
}

.top-bar button.dropdown-item.d-flex.align-items-center:after {
    content: "";
    position: absolute;
    top: -9px;
    right: 7%;
    transform: rotate(180deg);
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.top-bar button.dropdown-item.d-flex.align-items-center i.fa.fa-sign-out {
    font-size: 25px;
    margin-right: 5px;
}

.nav-item ul.dropdown-menu.dropdown-menu-end.show {
    margin-left: auto;
    width: auto;
    /* float: right; */
    margin-right: 0;
    margin-top: 15px;
    padding: 0;
    background: transparent;
}

nav ul li {
    list-style-type: none;
    text-align: left;
    padding: 0px 20px 30px;
    white-space: pre;
}

.sidenav nav li.active a {
    color: #4ABAEA;
    border-left: 0px solid #fff;
    /* padding: 20px 13px; */
}

nav ul {
    padding-left: 0;
}





.sidenav .sidenav {
    background: #171717;
    border-radius: 10px;
}

.sidenav .sidenav.py-2.col-2 {
    border-radius: 0px;
    border-right: 1px solid #2b2b2b;
}

.top-bar .col-2 {
    border-right: 1px solid #2b2b2b;
}

.sidenav {
    background: #000000;
    padding-bottom: 0 !important;
    height: auto;
    padding-top: 0px !important;
}

.sidenav ul li a {
    color: white;
    text-decoration: none;
    font-size: 18px;
}

.subscription select#formBasictext {
    background: url("../assets/images/arrow-down.png");
    background-position: right 0.75rem center;
    background-repeat: no-repeat;
}

.sidenav li img {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.col-lg-12.col-12.px-4.py-4.bg-black.user-table.user-details {
    min-height: auto;
}

.userlist tr th, .userlist tr td {
    color: white;
    text-align: left;
    padding: 15px 0px;
}

.userlist tr td:last-child, .userlist tr th:last-child {
    text-align: center;
}

.userlist {
    background: #171717;
    box-shadow: 0px 2px 34px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 24px 24px 24px;
}

.heading-top h2, .heading-top h5 {
    text-align: left;
}

.heading-top h2 {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    padding-bottom: 10px;
    font-size: 23px;
    line-height: 100%;
    color: #4ABAEA;
}

i.fa.fa-eye.eye-bg {
    background: #DEEBFF;
    color: #4abaea;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
}

i.fa.fa-edit.eye-bg {
    background: #E7FFDE;
    padding: 10px;
    border-radius: 50%;
    color: #1B6800;
    cursor: pointer;
}

.heading-top h5 {
    text-align: left;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #FFFFFF;
}

.table-main button#bd-theme {
    background: transparent;
    padding: 9px 30px;
    width: 170px;
    height: 38px;
    color: white;
    border-radius: 30px;
    border: 1px solid #D9D9D9;
}

.table-main li.nav-item.dropdown {
    list-style-type: none;
}

p.copywrite {
    font-family: 'Uber Move';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    color: #FFFFFF;
    opacity: 0.9;
    padding: 20px 24px 5px;
    margin: 0;
    text-align: left;
    bottom: 30px;
    border-top: 1px solid #E7E7E7;
    background: black;
}

.user-table .btn-filter button i {
    font-size: 13px;
    margin-left: 5px;
    vertical-align: middle;
    margin-top: 0;
}

.subscription .subscrip-name tr th, .subscription .subscrip-name tr td {
    padding: 0px 0px !important;
}




/* .h-85
{
height: 85vh;
} */

/* .sidenav .col-lg-2.col-12.py-5 {
    height: 100vh;
} */

.main-table-box tr:first-child {
    border-top: 1px solid rgba(235, 235, 235, 0.07);
}

.subscription button#bd-theme {
    background: #4ABAEA;
    color: #fff;
    display: flex;
    width: 195px;
    line-height: 1.1;
    padding: 0;
    height: 50px;
    border: none;
    align-items: center;
    justify-content: center;
}

div.add-more {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    color: #4ABAEA;
    background: transparent;
    box-shadow: none;
    border: none;
    text-align: right;
    float: right;
    margin-top: 10px;
    cursor: pointer;
}

.main-table-box input {
    background: #000000;
    border-radius: 100px;
    height: 50px;
    border: none;
    /* font-family: 'Uber Move Text'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #FFFFFF;
    padding-left: 30px;
    border: 1px solid #ced4da;
}

.main-table-box label.form-label {
    text-align: left;
    float: left;
    color: white;
}

.subscription button#bd-theme:after {
    display: none;
}

.subscription .btn.btn-filter.float-end li {
    list-style-type: none;
}

.d-flex.align-items-center.subscribe-main {
    justify-content: space-between;
}

.switch.btn {
    border-radius: 20px;
    min-width: 70px;
}

.switch-handle {
    position: relative;
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 70%;
    border-radius: 50px !important;
    left: 20px;
    width: 0px;
    border: none;
    top: 5px;
    border-width: 0 1px;
    background-color: #4ABAEA;
}

span.switch-off.btn.btn-success {
    background: #D9D9D9;
    color: #D9D9D9;
    border: none;
}

img.eye-bg {
    background: #DEEBFF;
    padding: 12px;
    border-radius: 50px;
    cursor: pointer;
}

img.edit-bg {
    background: #E7FFDE;
    padding: 12px;
    border-radius: 50px;
    margin-left: 15px;
    cursor: pointer;
}

tr {
    border-top: 1px solid rgba(235, 235, 235, 0.07);
    border-bottom: 1px solid rgba(235, 235, 235, 0.07);
}

.enterprice {
    background: #4ABAEA;
    box-shadow: 0px 6px 11px 4px rgba(0, 0, 0, 0.13);
    border-radius: 30px;
    text-align: left;
    padding: 30px 20px;
}



.enterprice ul li {
    list-style-type: none;
    text-align: left;
    color: white;
    padding: 6px 0px;
    display: flex;
    align-items: flex-start;
}

.enterprice h5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #182037;
}

.enterprice h2 span {
    font-size: 18px;
}

.enterprice h2 {
    color: white;
    font-style: normal;
    font-weight: 600;
    padding-bottom: 15px;
    font-size: 32px;
    line-height: 48px;
    color: #FFFFFF;
    border-bottom: 1px solid rgba(0, 0, 0, 0.17);
}

.subscrip-name tr th {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 45px;
    color: #4ABAEA;
}

.subscrip-name table td {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 45px;
    color: #FFFFFF;
}


/* toggle */
.user-toggle .switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 23px;
}

.user-toggle .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.user-toggle .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.user-toggle .slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}


.user-toggle input:checked+.slider {
    background-color: #4ABAEA;
}

.user-toggle input:focus+.slider {
    box-shadow: 0 0 1px #4ABAEA;
}

.user-toggle input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}


/* Rounded sliders */
.user-toggle .slider.round {
    border-radius: 34px;
}

.user-toggle .slider.round:before {
    border-radius: 50%;
}

.subscrip-name tr {
    border: none !important;
}

.enterprice ul {
    padding: 0;
    margin-top: 15px;
    max-height: 180px;
    overflow-y: scroll;
}

.changepass h1.mb-4 {
    color: white;
}

.enterprice ul::-webkit-scrollbar {
    width: 3px;
    background: white;
    height: 5px;
}

.enterprice ul::-webkit-scrollbar-thumb {
    width: 3px;
    background: rgb(253, 253, 253);
    height: 1px;
}

.enterprice ul::-webkit-scrollbar-track {
    width: 3px;
    background: #4ABAEA;
    height: 3px;
    max-height: 180px;
}

input[type="date"] {
    letter-spacing: 3px;
}

/* .text-white.no-data {
    position: absolute;
    left: 15%;
    right: 0;
    top: 60%;
    width: 75%;
    margin: auto;
} */

.main-table-box.table-responsive.userlist.subscript.mt-5 .enterprice {
    background: no-repeat;
    color: white;
    box-shadow: none;
}

.main-table-box.table-responsive.userlist.subscript.mt-5 .enterprice h5 {
    color: white;
}

.search-filter-all input.search-user {
    border: none;
    background: transparent;
    font-size: 16px;
    color: white;
    border-bottom: 1px solid #636363;
    border-radius: 0px;
    height: 40px;
}


.search-filter-all {
    position: relative;
}

.search-filter-all input:focus {
    outline: none;
    background: none;
}

select#formBasictext {
    background: transparent;
    border-radius: 20px;
    color: white;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABGSURBVHgB7Y1BCgAgCAR9iv//VE/ZIApCUtdzLnibGUV6awDGPiVYPbwNIItcMmxAs0jKRADzwAVpOYjwshOpyY9IXf5sEyHw4F9Km2TlAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center;
}

.search-filter-all i.search-user-data.fa.fa-search {
    position: absolute;
    left: 0;
    top: 8px;
    font-size: 18px;
    color: white;
}

input.search-user::-webkit-input-placeholder {
    color: white;
}

.main-table-box.table-responsive.userlist.subscript.mt-5 .enterprice ul {
    column-count: 3;
    /* display: flex; */
    justify-content: space-between;
    flex: 0 0 33.333%;
}

.main-table-box.table-responsive.userlist.subscript.mt-5 .enterprice h2 {
    color: #4abaea;
}

.main-table-box.table-responsive.userlist.subscript.mt-5 .enterprice h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 100%;
    color: #4ABAEA;
}

.note-disabled {
    font-size: 12px;
    font-style: italic;
    text-align: left;
}

span.crossbadge {
    width: 26px;
    height: 26px;
    background: #4abaea;
    /* padding: 10px 14px; */
    border-radius: 50%;
    color: white;
    position: relative;
    right: 15px;
    top: -38px;
    float: right;
    line-height: 1.7;
}

button.togglemenu {
    display: none;
}

.mobile {
    display: none;
}

.btn-danger span.switch-handle.btn.btn-light {
    left: -20px;
    background: white;
}

span.switch-on.btn.btn-danger {
    font-size: 0px;
}

.table-heading {
    display: flex;
    justify-content: space-between;
}

.enterprice.sub-detail {
    padding-left: 0;
}

.main-table-box.table-responsive.userlist.subscript.mt-5 {
    margin-top: 0 !important;
    position: relative;
    overflow: hidden;
}


.watermark-text h1 {
    font-size: 50px;
    opacity: 0.2;
    color: #166e90;
}

.shimmer-img {
    border-radius: 50%;
}

.trial-period {
    margin-top: 8px;
    color: #4cbbe8;
    opacity: 0.5;
}

.watermark-text {
    position: absolute;
    top: 158px;
    right: 70px;
    transform: rotate(-45deg);
}

button.btn.btn-sign {
    background: #4abaea !important;
    color: #fff !important;
    display: block;
    margin: 30px auto 0;
    width: 160px;
    border-radius: 30px;
    font-size: 1rem;
    margin-bottom: 20px;
    height: 45px;
}

a.forgot-pass {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #FFFFFF;
    text-align: right;
    float: right;
}

.login form p {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
}

.login form p a {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #4ABAEA;
    text-decoration: none;
}

.login form input {
    background: #000000;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    height: 61px;
    font-style: normal;
    font-weight: 500;
    padding: 0 20px;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}

.login form input::placeholder {
    opacity: 0.7;
}

.login form input:focus, input:focus {
    background: transparent;
    color: white;
}

.login form label.form-label {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    text-align: left;
    margin: 0;
    left: 13px;
    float: left;
    padding: 0 8px 0;
    position: relative;
    top: 10px;
    z-index: 1;
    background: black;
}

body form select {
    background-color: #000000 !important;
    border-radius: 100px !important;
    height: 50px;
    border: none;
    /* font-family: 'Uber Move Text'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #FFFFFF !important;
    padding-left: 30px;
}

.heading-top h5 a {
    color: white;
    text-decoration: none;
}

.fa.eye-password {
    position: absolute;
    cursor: pointer;
    right: 15px;
    color: white;
    top: 38px;
}

.form-select:disabled {
    cursor: not-allowed;
}

.loader {
    width: 100%;
    height: 100vh;
    position: fixed;
    background: #000000bf;
    top: 0;
    z-index: 9999;
}

.loader div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

p.logo-sub-title span {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #4ABAEA;
}

p.logo-sub-title {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    position: relative;
    top: -8px;
}




span.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    border-radius: 3px;
    width: 20px;
    background-color: #ffffff;
}

label.check-tick.form-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

label.check-tick.form-label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 17px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}



.check-tick .checkmark:after {
    left: 7px;
    top: 4px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

h4.free-trial-avail {
    font-size: 20px;
    font-style: italic;
    color: #4abaea;
}

.profile-pic i.fa.fa-pencil {
    background: white;
    padding: 5px;
    border-radius: 5px;
}



.dashboard-data .col-lg-12.col-12.px-4.py-4.bg-black.user-table {
    overflow: hidden;
    max-height: 725px;
}


.profile-pic {
    position: relative;
    border-radius: 10px;
    min-height: 200px;
    width: 200px;
    height: 200px;
    margin: auto;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.check-tick input:checked~.checkmark:after {
    display: block;
}

.check-tick input:checked~.checkmark {
    background-color: #2196F3;
}

.login img {
    max-width: 330px;
}

.bg-login {
    background: url("../assets/images/bg-login.png") no-repeat;
    align-items: center;
    display: flex;
    height: 100vh;
}

.swal2-styled.swal2-confirm:focus {
    box-shadow: none;
}


.login-data input:focus {
    outline: none;
    box-shadow: none;
    border-color: white;
}

.userlist tr td:last-child img {
    cursor: pointer;
}

.error-msg {
    color: red;
}

.swal2-styled.swal2-confirm {
    color: #fff;
    background: #4abaea !important;
    border: #4abaea !important;
}

.login-data {
    text-align: left;
}

.login-data span {
    text-align: center;
}

input:-webkit-autofill {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: #fff;
}

ul.pagination li.active {
    background: #4ABAEA;
    border-radius: 50%;
}


ul.pagination li {
    width: 40px;
    font-size: 20px;
    height: 40px;
    background: white;
    justify-content: center;
    border-radius: 50%;
    margin: 0 5px;
    vertical-align: middle;
    display: flex;
    align-items: center;
}

ul.pagination li.active a {
    color: white;
}


ul.pagination li:first-child, ul.pagination li:last-child {
    display: none;
}

span.view-reason {
    color: #4cbbe8;
    cursor: pointer;
    text-decoration: underline;
    display: block;
    font-size: 14px;
}

.view-cancel .modal-dialog.modal-dialog-centered .modal-content {
    height: 200px;
}

.view-cancel .modal-dialog.modal-dialog-centered {
    max-width: 600px;
}


.subscription .btn-filter button#bd-theme i {
    color: #4abaea;
    background: white;
    padding: 4px 6px;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 0;
}



ul.pagination li:nth-of-last(1) {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

ul.pagination li:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

input.add-subscription {
    padding: 0 30px;
    background: #4ABAEA;
    color: #fff;
    float: left;
    margin: 30px 0px;
}

.zoom-in-zoom-out {
    animation: zoom-in-zoom-out 3s ease-out infinite;
}

select#formBasictext option {
    background: #171717 !important;
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.1, 1.1);
    }

    100% {
        transform: scale(1, 1);
    }
}

h2#swal2-title {
    font-size: 20px;
    padding-top: 1.25em;
}


.col-lg-12.col-12.px-4.py-4.bg-black.user-table {
    overflow-y: scroll;
    max-height: 740px;
}

.col-lg-12.col-12.px-4.py-4.bg-black.user-table::-webkit-scrollbar {
    width: 3px;
    background: white;
    height: 5px;
}

.col-lg-12.col-12.px-4.py-4.bg-black.user-table::-webkit-scrollbar-thumb {
    width: 3px;
    background: rgb(253, 253, 253);
    height: 1px;
}

.col-lg-12.col-12.px-4.py-4.bg-black.user-table::-webkit-scrollbar-track {
    width: 3px;
    background: rgb(99, 99, 99);
    height: 3px;
    max-height: 180px;
}

.enterprice.sub-detail ul {
    overflow: unset;
}

.search-right-main {
    display: flex;
    align-items: center;
}

.sidenav.py-2.col-2 {
    background-image: url("http://localhost:3000/static/media/bg-login.6d49d72f628c3d3d8117.png");
    background-repeat: no-repeat;
    background-size: cover;
}

body::-webkit-scrollbar {
    width: 0px;
}

.top-bar button.dropdown-item.d-flex.align-items-center {
    justify-content: center;
    background: white;
    color: black;
    border-radius: 0px;
    width: auto;
    float: left;
    padding: 7px 14px;
    border-radius: 5px;
}

.footer-dashboard .col-lg-10.col-12.px-5.py-5.bg-black.h-85 {
    padding-bottom: 30px !important;
}

.search-user-data {
    cursor: pointer;
    color: #4ABAEA;
}

button.btn.back-login {
    color: white;
    text-align: center;
    width: 100%;
}

button.btn.back-login i.fa.fa-chevron-left {
    margin-right: 10px;
}

.swal2-container.swal2-center>.swal2-popup {
    grid-column: 2;
    grid-row: 2;
    align-self: center;
    justify-self: center;
    width: 400px;
}

img.hamburger {
    filter: invert(1);
    float: left;
    margin-left: 30px;
    margin-top: 10px;
    display: block;
    cursor: pointer;
}

.cancel-sub button {
    background: linear-gradient(180deg, #4abaea 0%, #15a6e5 100%);
    width: 100%;
    padding: 12px;
    color: #ffff;
    font-size: 14px;
    border-radius: 40px;
    margin-top: 15px;
}

.full-width-sidebar.active-new {
    width: 100%;
}

.active-new img.hamburger {
    margin-top: 30px;
}

.active-new .header-right.float-end {
    padding: 35px 0px;
}

.btn.btn-filter.float-end {
    display: flex;
    align-items: center;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
    margin-right: 10px;
}

.btn.btn-filter.float-end div {
    margin: 0 10px;
}

.btn.btn-filter.float-end button.btn.btn-unset {
    color: #4abaea;
    border: 1px solid;
    border-radius: 50px;
    padding: 10px 30px;
}

.profile-pic img {
    width: 100%;
    border-radius: 50%;
    border: 1px solid #fff;
    height: 100%;
    object-fit: cover;
}

ul.dropdown-menu.dropdown-menu-end.admin-drop.show {
    background: white;
    width: 200px;
    padding: 0px;
}

ul.dropdown-menu.dropdown-menu-end.admin-drop.show li:first-child {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-top: 10px;
    text-shadow: none;
    cursor: pointer;
    color: black;
}

ul.dropdown-menu.dropdown-menu-end.admin-drop.show li:first-child i.fa.fa-eye-slash {
    margin-right: 5px;
    font-weight: bold;
}



@media (min-width:1280px) and (max-width:1550px) {

    .sidenav ul li a {
        font-size: 14px;
    }

    .sidenav .sidenav.py-2.col-2 {
        border-radius: 0px;
        padding: 0 6px;
    }

    .sidenav li img {
        margin-right: 10px;
        width: 15px;
    }



    nav ul li {
        list-style-type: none;
        text-align: left;
        padding: 20px 15px;
        white-space: pre;
    }



    .main-table-box input {
        font-size: 13px;
    }

    .login img {
        max-width: 150px;
    }

    p.logo-sub-title, p.logo-sub-title span {
        top: 0;
        font-size: 16px;
    }

    .numbers h4.card-title {
        font-size: 35px;
        font-weight: bold;
    }

    p.card-category {
        font-size: 16px;
    }
}



@media (min-width:1097px) and (max-width:1098px) {
    .login img {
        max-width: 150px;
    }

    .sidenav .sidenav.py-2.col-2 {
        border-radius: 0px;
        padding: 0;
    }

    .sidenav li img {
        width: 15px;
    }

    .sidenav ul li a {
        color: white;
        display: flex;
        text-decoration: none;
        align-items: center;
        font-size: 10px;
    }


    p.logo-sub-title, p.logo-sub-title span {
        top: 0;
        font-size: 16px;
    }

    .fa.eye-password {
        top: 28px;
    }

    .bg-login {
        height: auto;
        padding: 20px;
    }

    .login form input {
        background: #000000;
        border: 1px solid #FFFFFF;
        border-radius: 10px;
        height: 40px;
        font-style: normal;
        font-weight: 500;
        padding: 0 20px;
        font-size: 12px;
        line-height: 19px;
        color: #FFFFFF;
    }

    button.btn.btn-sign {
        background: #4ABAEA;
        border-radius: 10px;
        width: 100%;
        margin: 20px 0px;
        height: 40px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #FFFFFF;
    }
}




@media (min-width:992px) and (max-width:1366px) {
    .userlist .col-lg-3 {
        width: 45%;
    }

    .bg-login {

        height: 100vh;
    }
}

@media (max-width:992px) {

    .sidenav nav {
        display: none;
    }

    .mobile nav {
        display: flex;
    }

    .mobile {
        display: block;
    }

    .sidenav .col-lg-2.col-12.py-5 {
        height: auto;
    }
}


@media (min-width:960px) and (max-width:1023px) {
    .bg-login {
        height: auto;
    }
}


@media (min-width:1024px) and (max-width:1025px) {
    body .sidenav .sidenav.py-2.col-2 {
        border-radius: 0px;
        width: 16.66666667%;
    }
}

@media (min-width:768px) and (max-width:1024px) {

    .sidenav {
        height: auto;
    }

    .sidenav li img {
        margin-right: 10px;
        width: 13px;
        height: 13px;
        object-fit: contain;
    }

    .sidenav ul li a {
        font-size: 10px;
    }

    .sidenav .sidenav.py-2.col-2 {
        border-radius: 0px;
        width: 100%;
    }

    .login img {
        max-width: 270px;
    }

    .numbers h4.card-title {
        font-size: 30px;
        font-weight: bold;
    }

    p.logo-sub-title {
        top: -10px;
    }

    p.card-category {
        font-size: 16px;
        white-space: nowrap;
    }

    .card-stats.card {
        font-size: 20px !important;
        padding: 0px;
    }

    p.copywrite {
        font-family: 'Uber Move';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        position: unset;
        text-align: center;
        width: 100%;
        margin-top: 30px;
    }

    nav ul li {
        list-style-type: none;
        text-align: left;
        padding: 20px 5px;
        font-size: 12px;
        white-space: pre;
    }

    .top-bar {
        padding: 30px 0px;
    }

    .enterprice ul li {
        font-size: 13px;
    }

    .userlist tr th, .userlist tr td {
        color: white;
        text-align: left;
        padding: 15px 0px;
        font-size: 14px !important;
    }

}

@media (max-width:767px) {

    .profile-pic {
        margin-bottom: 40px;
        margin-top: 40px;
    }


    .table-heading {
        display: block;
        justify-content: space-between;
    }

    .heading-top h5 {
        line-height: 30px;
    }

    img.hamburger {
        display: none;
    }


    .card-stats.card {
        padding: 0;
    }

    .card-stats {
        margin-bottom: 20px;
    }

    .sidenav .sidenav.py-2.col-2 {
        width: 100%;
    }

    .navbar-nav a img {
        display: none;
    }

    .numbers h4.card-title {
        font-size: 30px;
        font-weight: bold;
    }

    body .card-stats.card {
        font-size: 20px !important;
    }

    .main-table-box.table-responsive {
        padding: 15px;
    }

    .footer-dashboard .col-lg-2.col-12.py-5 {
        display: none;
    }

    .login img {
        max-width: 100%;
    }

    body button.togglemenu {
        display: block;
        background: transparent;
        border: none;
    }

    .sidenav .col-lg-2.col-12.py-5 {
        height: auto;
    }

    .mobile {
        display: block;
    }

    button.navbar-toggler:focus {
        border: none;
        box-shadow: none;
    }

    .sidenav nav {
        display: none;
    }

    .mobile nav {
        display: flex;
    }

    .togglemenu span {
        margin: 7px 0px;
        display: block;
        height: 2px;
        width: 30px;
        background: white;
        margin-bottom: 4px;
    }

    p.copywrite {
        position: unset;
        color: #ffffff;
        opacity: 0.9;
        padding: 30px 0px 30px;
        width: 100%;
        text-align: center;
        font-size: 16px;
    }

    .main-table-box.table-responsive.userlist.subscript.mt-5 .enterprice ul {
        column-count: 1;
    }


    .switch.btn {
        border-radius: 20px;
        min-width: 75px;
        margin: 0;
        padding: 0;
    }

    .d-flex.align-items-center.subscribe-main {
        display: block !important;
        margin-bottom: 40px !important;
        margin-top: 40px;
    }

    .col-lg-10.col-12.px-5.py-5.bg-black.h-85 {
        padding: 10px !important;
    }

    .sidenav {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }

    p.logo-sub-title {
        top: -10px;
    }

    .table-main button#bd-theme {
        position: unset !important;
    }

    .table-main button#bd-theme svg.bi.theme-icon-active {
        display: none;
    }

    .top-bar {
        padding-bottom: 20px;
        padding-top: 20px;
    }

    table {
        table-layout: fixed;
    }

    .userlist tr th {
        width: 155px;
        font-size: 14px !important;
    }

    .main-table-box.table-responsive {
        width: 100%;
        margin-bottom: 40px;
    }

    .userlist tr th, .userlist tr td {
        font-size: 11px;
    }

    .h-85 {
        height: auto;
    }

    .btn.btn-filter.float-end {
        margin-bottom: 20px;
    }
}




@media (min-width:540px) and (max-width:767px) {
    .sidenav {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        height: auto;
    }

    .bg-login {
        height: auto;
    }
}

@media (max-width:375px) {
    .sidenav {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        height: auto;
    }

    .bg-login {
        height: auto;
    }
}